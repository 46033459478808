import connectionInstance from './connection-instance';
export const url = process.env.REACT_APP_API_BASEURL;
export const authUrl = process.env.REACT_APP_AUTH_API_URL;


export const getAssetData = () =>
  connectionInstance.get(`${url}/api/assets/`, {});

export const getUnallcatedData = () =>
  connectionInstance.get(`${url}/api/unallocated-assets/`, {});

export const getUserRequest = () =>
  connectionInstance.get(`${url}/api/user-requests/`, {});

export const getRequestData = (data) =>
  connectionInstance.post(`${url}/api/asset_requests/`, data);

export const getUserData = () =>
  connectionInstance.post(`${authUrl}/getCurrentUser/`, {});

export const getLoginData = (data) =>
  connectionInstance.post(`${authUrl}/login-user/`, data);

export const postMaintenanceRequest = (data) =>
  connectionInstance.post(`${url}/api/maintenance-request/`, data);