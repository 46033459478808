import React, { useContext, useState } from 'react';
import { handleSignOut } from 'service/firebase';
import { getAuthToken, decodeFirebaseIdToken } from 'utils/authToken';
import { AUTH_TOKENS } from 'utils/constants';

const AuthContext = React.createContext();

const getCurrentUserName = (email) => {
  return email.split('@')[0];
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useContext Not Defined!');
  }
  return context;
};

export { useAuthContext, AuthContext };

export function AuthProvider({ children }) {
  let initUserData = {};
  if (getAuthToken(AUTH_TOKENS.ID_TOKEN)) {
    initUserData = decodeFirebaseIdToken(getAuthToken(AUTH_TOKENS.ID_TOKEN));
    initUserData = {
      ...initUserData,
      userName: getCurrentUserName(initUserData.email),
    };
    const domain = initUserData.email.split('@')[1];

    if (domain !== 'ignitesol.com') {
      handleSignOut()
    }
    // console.log(initUserData, "User Data");
  }
  const [isAuthenticated, setAuthenticated] = useState(
    !!getAuthToken(AUTH_TOKENS.ID_TOKEN)
  );
  const [currentUser, setCurrentUser] = useState(initUserData);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isAuthenticated,
        setAuthenticated,
        setCurrentUser,
      }}
    >
      {currentUser && children}
    </AuthContext.Provider>
  );
}