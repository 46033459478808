import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const MaintenanceModal = ({ isOpen, toggle, selectedAsset, handleSubmit, title }) => {
  const [maintenanceReason, setMaintenanceReason] = useState('');
  const [error, setError] = useState('');

  const handleFormSubmit = () => {
    if (!maintenanceReason) {
      setError('Please provide a reason for maintenance.');
      return;
    }
    
    handleSubmit(maintenanceReason);
    setMaintenanceReason('');
    setError('');
  };

  const handleToggle = () => {
    setMaintenanceReason('');
    setError('');
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <ModalHeader>{title} Request</ModalHeader>
      <ModalBody>
        <label className='maintenance-tag'>Asset</label>
        <input className='maintenance-asset' type="text" value={selectedAsset.ignite_tag_id} readOnly />
        <label className='maintenance-tag'>Reason for {title}</label>
        <input className='maintenance-reason' type="text" value={maintenanceReason} onChange={e => setMaintenanceReason(e.target.value)} placeholder="Describe here"/>
        {error && <p className="error-message">{error}</p>}
      </ModalBody>
      <ModalFooter>
        <button className='maintenance-button' onClick={handleFormSubmit}>Submit</button>
        <button className='maintenance-button-back' onClick={handleToggle}>Back</button>
      </ModalFooter>
    </Modal>
  );
};

export default MaintenanceModal;
