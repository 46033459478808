import axios from 'axios';
import { AUTH_TOKENS } from 'utils/constants';
import { getAuthToken } from "utils/authToken";
import { handleSignOut } from './firebase';

const TOKEN_URL = `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_FIREBASE_API_KEY}`;
const connectionInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASEURL}`,
  timeout: 30000,
  crossdomain: true,
});

export const getNewToken = async () => {
  try {
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", getAuthToken(AUTH_TOKENS.REFRESH_TOKEN));
    const res = await axios.post(`${TOKEN_URL}`, params, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    if (res && res.data) {
      document.cookie = `${AUTH_TOKENS.ID_TOKEN}=${res.data.id_token}; domain=.ignitesol.com; path=/;`;
      return res.data.id_token;
    }
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

connectionInstance.interceptors.request.use(
  (config) => {
    const idToken = getAuthToken(AUTH_TOKENS.ID_TOKEN);
    if (idToken) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

connectionInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error && error.response && error.response.status !== 401) {
      return Promise.reject(error);
    }

    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      error.config.url === TOKEN_URL
    ) {
      console.error("Session expired. Please login again.");
      handleSignOut();
      return Promise.reject(error);
    }

    try {
      const token = await getNewToken();
      if (token) {
        const config = error.config;
        config.headers.Authorization = `Bearer ${token}`;
        return await axios.request(config);
      }
      // logout
      handleSignOut();
      return Promise.reject(error);
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

export default connectionInstance;