import React, { useState, useEffect, useContext } from "react";
import Header from "./Header";
import MaintenanceModal from "Modal/MaintenanceModal";
import AssetModal from "../Modal/AssetModal";
import NoData from "./NoData";
import { Card, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "../css/Home.css";
import { format } from "date-fns";
import {
   getAssetData,
  getUserRequest,
  getUnallcatedData,
  postMaintenanceRequest,
} from "service/api";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { AiFillPlusCircle } from "react-icons/ai";
import { BsFunnelFill } from "react-icons/bs";
import { AuthContext } from "contexts/AuthContext";

const Home = ({ user }) => {
  const [assets, setAssets] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRequests, setUserRequests] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [maintenanceModalOpen, setMaintenanceModalOpen] = useState(false);
  const [assetModalOpen, setAssetModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [unallocatedAssets, setUnallocatedAssets] = useState([]);
  const [modalType, setModalType] = useState(null);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState(["in service", "under maintenance"]);

  const { currentUser } = useContext(AuthContext);

  const updateSelectedCategory = (category) => {
    setSelectedCategory(category);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleTagClick = (asset) => {
    setSelectedAsset(asset);
    toggleModal();
  };

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    if (selectedAsset) {
      const allocation = allocations.find((a) => a.asset === selectedAsset.id);
      const status = allocation?.return_date ? "returned" : selectedAsset.status;
      setShowButtons(status === "in service" || status === "under maintenance");
    }
  }, [selectedAsset, allocations]);

  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const newStatusOptions = [
      { label: "In-service", value: "in service" },
      { label: "Under Maintenance", value: "under maintenance" },
      { label: "Retired", value: "retired" },
    ];

    const hasReturnedAssets = allocations.some(
      (allocation) => allocation.return_date !== null
    );

    if (hasReturnedAssets) {
      newStatusOptions.push({ label: "Returned", value: "returned" });
    }

    setStatusOptions(newStatusOptions);
  }, [allocations]);


  const handleStatusChange = (status) => {
    if (selectedStatuses.includes(status)) {
      setSelectedStatuses(
        selectedStatuses.filter((selectedStatus) => selectedStatus !== status)
      );
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getAssetData();
      // console.log(response);
      if (response.data.asset.length > 0) {
        setAssets(response.data.asset);
        setAllocations(response.data.allocate);
      } else {
        setAssets([]);
        setAllocations([]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserRequests = async () => {
    try {
      const response = await getUserRequest();
      setUserRequests(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleAssetModal = () => {
    // new function
    setAssetModalOpen(!assetModalOpen);
  };

  const refecthdata = () => {
    fetchUserRequests();
  };

  useEffect(() => {
    fetchData();
    fetchUserRequests(); // fetch user requests when the component mounts
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 3000);
  }, []);

  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMMM d, yyyy");
  };

  const handleServiceRequest = (maintenanceReason) => {
    const payload = {
      user_email: currentUser.email,
      asset_id: selectedAsset.id,
      reason: maintenanceReason,
      request_type: modalType.toLowerCase(),
    };

    postMaintenanceRequest(payload)
      .then(() => {
        alert(`${modalType} request sent successfully.`);
        setMaintenanceModalOpen(!maintenanceModalOpen);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.data) {
          alert(error.response.data.message);
        } else {
          alert(`An error occurred while sending the ${modalType} request.`);
        }
      });
  };

  const openRequestModal = async () => {
    try {
      const response = await getUnallcatedData();
      setUnallocatedAssets(response.data.unallocated_assets);
      setCategories(
        response.data.all_categories.map((cat) => cat.category_type)
      );
      setModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const filteredAssets = () => {
    if (selectedCategory === "") {
      return unallocatedAssets;
    } else {
      return unallocatedAssets.filter(
        (asset) => asset.category === selectedCategory
      );
    }
  };

  const refreshRequestedAssets = () => {
    fetchUserRequests();
  };


  return (
    <>
      {user && (
        <Header
          user={currentUser}
          fetchSuperUser={true}
          onAssetRequestSuccess={() => refecthdata()}
        />
      )}

      {loading ? (
        <div className="loader-wrapper">
          <div className="loader"></div>
          <p className="loading-text">Loading...</p>
        </div>
      ) : (
        <Card className="table-margin">
          <h2>Allocated Assets</h2>
          <CardBody>
            {assets.length > 0 ? (
              <Table className="assets-list-grid">
                <Thead>
                  <Tr>
                    <Th>Ignite Tag ID</Th>
                    <Th>Category</Th>
                    <Th>Make</Th>
                    <Th>Model</Th>
                    <Th>Date Assigned</Th>
                    <Th>
                      Status
                      <BsFunnelFill
                        size={20}
                        color={selectedStatuses.length > 0 ? "#242d42" : "#fff"}
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={() => {
                          setFilterDropdownOpen(!filterDropdownOpen);
                          setSelectedStatuses(
                            selectedStatuses.length > 0 ? selectedStatuses : []
                          );
                        }}
                      />
                      <div
                        className="status-filter"
                        style={{
                          display: filterDropdownOpen ? "block" : "none",
                        }}
                      >
                        {statusOptions.map((option) => (
                          <div className="filter-option" key={option.value}>
                            <input
                              type="checkbox"
                              value={option.value}
                              checked={selectedStatuses.includes(option.value)}
                              onChange={(event) =>
                                handleStatusChange(event.target.value)
                              }
                            />
                            {option.label}
                          </div>
                        ))}
                      </div>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {assets
                    .filter((asset) => {
                      const allocation = allocations.find((a) => a.asset === asset.id);
                      const status = allocation && allocation.return_date ? "returned" : asset.status;
                      return (
                        selectedStatuses.length === 0 ||
                        selectedStatuses.includes(status.toLowerCase())
                      );
                    })
                    .map((asset) => {
                      const allocation = allocations.find((a) => a.asset === asset.id);
                      let status = asset.status.toLowerCase();

                      // Determine the status based on the return date
                      if (allocation && allocation.return_date) {
                        status = "returned";
                      }
                      const dateToDisplay = allocation ? formatDate(allocation.date_assigned) : "-";

                      return (
                        <Tr
                          key={asset.id}
                          className="clickable-row"
                          onClick={() => {
                            handleTagClick(asset);
                            toggleAssetModal();
                          }}
                        >
                          <Td className="clickableTag">{asset.ignite_tag_id}</Td>
                          <Td>{asset.category}</Td>
                          <Td>{asset.make}</Td>
                          <Td>{asset.model}</Td>
                          <Td>{dateToDisplay}</Td>
                          <Td>{status}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            ) : (
              <NoData />
            )}
          </CardBody>
        </Card>
      )}

      <Modal isOpen={modal} toggle={toggleModal} className="custom-modal">
        <ModalHeader className="asset-info-head">
          Asset Info - {selectedAsset ? selectedAsset.ignite_tag_id : ""}
          <button
            className="close-btn"
            type="button"
            style={{
              position: "absolute",
              right: "15px",
              background: "none",
              border: "none",
              fontSize: "40px",
              top: "1px",
            }}
            onClick={toggleModal}
          >
            &times;
          </button>
        </ModalHeader>

        <ModalBody className="modal-body-content">
          {selectedAsset && (
            <>
              <Table>
                <Tbody>
                  <Tr>
                    <Td className="asset-info-td">Make</Td>
                    <Td>{selectedAsset.make}</Td>
                  </Tr>
                  <Tr>
                    <Td className="asset-info-td">Model</Td>
                    <Td>{selectedAsset.model}</Td>
                  </Tr>
                  <Tr>
                    <Td className="asset-info-td">Category</Td>
                    <Td>{selectedAsset.category}</Td>
                  </Tr>
                  <Tr>
                    <Td className="asset-info-td">Date of Purchase</Td>
                    <Td>{formatDate(selectedAsset.date_purchased)}</Td>
                  </Tr>
                  <Tr>
                    <Td className="asset-info-td">Warranty End Date</Td>
                    <Td>{formatDate(selectedAsset.warranty_end_date)}</Td>
                  </Tr>
                  <Tr>
                    <Td className="asset-info-td">Asset Vendor</Td>
                    <Td>
                      {selectedAsset.asset_vendor ? (
                        <a
                          href={selectedAsset.asset_vendor}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {selectedAsset.asset_vendor}
                        </a>
                      ) : (
                        "-"
                      )}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td className="asset-info-td">RAM</Td>
                    <Td>{selectedAsset.ram}</Td>
                  </Tr>
                  <Tr>
                    <Td className="asset-info-td">Hard Disk</Td>
                    <Td>{selectedAsset.hard_disk}</Td>
                  </Tr>
                  <Tr>
                    <Td className="asset-info-td">Processor</Td>
                    <Td>{selectedAsset.processor}</Td>
                  </Tr>
                  <Tr>
                    <Td className="asset-info-td">Serial Number</Td>
                    <Td>{selectedAsset.serial_number}</Td>
                  </Tr>
                </Tbody>
              </Table>

              <MaintenanceModal
                isOpen={maintenanceModalOpen}
                toggle={() => setMaintenanceModalOpen(!maintenanceModalOpen)}
                selectedAsset={selectedAsset}
                handleSubmit={handleServiceRequest}
                title={modalType}
              />

              {showButtons ? (
                <div className="button-group">
                  <button
                    className="asset-button"
                    onClick={() => {
                      setModalType("Maintenance");
                      setMaintenanceModalOpen(true);
                    }}
                  >
                    Maintenance
                  </button>
                  <button
                    className="asset-button"
                    onClick={() => {
                      setModalType("Return");
                      setMaintenanceModalOpen(true);
                    }}
                  >
                    Return
                  </button>
                  <button
                    className="asset-button"
                    onClick={() => {
                      setModalType("Retired");
                      setMaintenanceModalOpen(true);
                    }}
                  >
                    Retire
                  </button>
                </div>
              ) : (
                <p className="asset-status-prompt">
                  ** Requests for this asset are not allowed.
                </p>
              )}
            </>
          )}
        </ModalBody>
      </Modal>

      <Card className="table-margin">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Requested Assets</h2>
          <button className="request-asset-button" onClick={openRequestModal}>
            <AiFillPlusCircle size={22} color="#495678" /> Request Asset
          </button>

          {userRequests.length > 0 ? (
            <button
              onClick={toggleTable}
              className="chevron-button"
              style={{
                outline: "none",
                MozFocusInner: "none",
                boxShadow: "none",
              }}
            >
              {showTable ? (
                <FaChevronUp size={20} />
              ) : (
                <FaChevronDown size={20} />
              )}
            </button>
          ) : (
            <button
              className="chevron-button"
              style={{
                visibility: "hidden",
              }}
            >
              <FaChevronDown size={20} />
            </button>
          )}
        </div>

        {showTable && userRequests.length > 0 && (
          <CardBody>
            <Table>
              <Thead>
                <Tr>
                  <Th>Ignite Tag ID</Th>
                  <Th>Category</Th>
                  <Th>Make</Th>
                  <Th>Model</Th>
                  <Th>Date Requested</Th>
                  <Th>Request Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {userRequests.map((request, index) => (
                  <Tr key={index}>
                    <Td>{request.asset?.ignite_tag_id || "-"}</Td>
                    <Td>
                      {request.asset?.category || request.category || "New"}
                    </Td>
                    <Td>{request.asset?.make || request.make}</Td>
                    <Td>{request.asset?.model || request.model}</Td>
                    <Td>{formatDate(request.created_at)}</Td>
                    <Td>{request.request_status}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </CardBody>
        )}
      </Card>

      <AssetModal
        isModalOpen={isModalOpen}
        closeModal={() => setModalOpen(false)}
        categories={categories}
        filteredAssets={filteredAssets}
        updateSelectedCategory={updateSelectedCategory}
        refreshRequestedAssets={refreshRequestedAssets} // Add this line
      />
    </>
  );
};

export default Home;
