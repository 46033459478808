import React from "react";
import Home from "./components/Home";
import { AuthProvider } from './contexts/AuthContext';
import AuthenticatedRoute from 'utils/AuthenticatedRoute';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "components/Header";


function App() {

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
              <AuthProvider>
                <AuthenticatedRoute>
                  <Header/>
                  <Home />
                  </AuthenticatedRoute>
              </AuthProvider>
          }
        />
      </Routes>
    </Router>

  );
}

export default App;