// export const signInWithGoogle = async (onError) => {
//   try {
//     const result = await auth.signInWithPopup(provider);
//     const user = result.user;
//     const domain = user.email.split('@')[1];

//     if (domain !== 'ignitesol.com') {
//       await auth.signOut();
//       onError('Only users with @ignitesol.com domain can access this application');
//       return;
//     }

//     const token = await user.getIdToken();
//     if (token) {
//       localStorage.setItem("token", token);
//       window.location.href = '/asset';
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };


import { deleteAllAuthToken } from "utils/authToken";

export const handleSignOut = () => {
  deleteAllAuthToken();
  window.location.href = `${process.env.REACT_APP_AUTH_URL}?next=${window.location.href}`;
};