import React from 'react';

import "../css/NoData.css";  // Importing CSS file

function NoData() {
    return (
        <div className="no-data-container">  
            <img src="/images/nodata.jpg" alt="No data available" className="no-data-image"/> 
            <h5>No Allocations Found</h5>
        </div>
    );
}

export default NoData;
